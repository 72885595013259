/* rem and em do NOT depend on HTML font-size in media queries 
instead, 1rem = 1em = 16px */

/* rem has some bugs in some browsers when used in media
 queries, so use em */

/**************************************/
/* L -- BELOW 1200 */
/**************************************/

/* 1200px / 16px = 75 */
@media (max-width: 75em) {
  .container {
    margin: 3.2rem auto;
  }
}

/**************************************/
/* M -- BELOW 992 */
/**************************************/

/* 992px / 16px = 62 */
@media (max-width: 62em) {
  h2 {
    font-size: 3.2rem;
  }

  .intro-cards {
    grid-template-columns: 1fr;
  }
}

/**************************************/
/* S -- BELOW 768 */
/**************************************/

/* 768px / 16px = 48 */
@media (max-width: 48em) {
  .banner {
    justify-content: stretch;
  }

  .banner > a {
    width: 100%;
    justify-content: center;
  }

  h2 {
    font-size: 2.4rem;
  }

  h2.text-center {
    font-size: 3.2rem;
  }

  .hover-tile:hover {
    top: 0;
  }

  .section-hero {
    /* vh doesn't work well on iOS */
    height: auto;
    /* padding: 0; */
    justify-content: flex-start;
  }

  .scroll-prompt {
    display: none;
  }

  .header-content {
    margin-top: 1.2rem;
    background-color: transparent;
    padding: 4rem 0;
    width: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-content > h1 {
    width: 100%;
    text-align: center;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  }

  .header-content > h2 {
    max-width: 48rem;
  }

  .resume-container {
    flex: 1;
  }

  .resume-download {
    display: none;
  }

  .header-content {
    backdrop-filter: none;
  }

  .header-content h1 {
    font-size: 3.2rem;
  }

  .header-content h2 {
    font-size: 2rem;
    margin-bottom: 1.8rem;
  }

  .header-content p {
    font-size: 1.6rem;
    line-height: 1.4;
  }

  .header-links {
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 1.2rem;
  }

  .intro-image {
    margin: 0 0 2rem 0;
  }

  .container {
    padding: 2rem 3.2rem;
  }

  .certificates-list {
    grid-template-columns: 1fr;
  }

  ul.masonry {
    column-count: 1;
  }

  .project-card-img-container {
    height: 16rem;
  }

  .project-card img {
    height: fit-content;
    opacity: 1;
  }

  .tag-container {
    justify-content: center;
  }

  footer > .footer-content {
    padding: 3.2rem 4rem;
  }
}
